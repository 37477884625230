import { StaticQuery, graphql } from 'gatsby';

import { IndexPage } from '../pageTemplates/index';
import { LanguageContext } from 'common/src/contexts/LanguageContext';
import React from 'react';
import { withPreview } from 'gatsby-source-prismic-graphql';

const seoDataQuery = graphql`
  query {
    prismic {
      allLanding_pages(lang: "ru") {
        edges {
          node {
            document_seo_title
            document_seo_description
            hero_promotion_highlighted_prefix
            hero_promotion_suffix
            hero_subtitle
            hero_title
            hero_image
            hero_button_text
            hero_button_link
            hero_video_button_text
            heri_text_button_link
            contact_form_title
            contact_form_subtitle
            faq_subtitle
            faq_tile
            faq_button_text
            faq_button_link
            faq {
              text
              title
            }
            advantages_subtitle
            advantages_title
            advantages_items {
              image
              text
              title
            }
            partnership_title
            partnership_text
            partnership_image
            partnership_button_text
            partnership_button_link
            menu {
              label
              path
              scroll_offset
            }
            navigation_cta_label
            navigation_cta_path
            navigation_cta_scroll_offset
            info_section_title
            info_section_text
            info_section_image
            info_section_cta
            info_section_cta_link
            pricing_group_one {
              button_text
              items1
              price
              price_description
              price_button_link
              text
              title
            }
            pricing_group_one_title
            pricing_group_two {
              button_text
              items1
              price
              price_button_link
              price_description
              text
              title
            }
            pricing_group_two_title
            pricing_subtitle
            pricing_title
            reviews_title
            reviews_subtitle
            reviews {
              image
              name
              position
              profile_url
              text
              title
            }
            promo_button_link
            promo_button_text
            promo_link_text
            promo_link_url
            promo_text
            promo_title
            study_process_title
            study_process_subtitle
            working_items {
              image
              text
              title
            }
          }
        }
      }
    }
  }
`;

export default () => {
  return (
    <LanguageContext.Provider value={{ lang: 'ru' }}>
      <StaticQuery
        query={`${seoDataQuery}`}
        render={withPreview(IndexPage, seoDataQuery)}
      />
    </LanguageContext.Provider>
  );
};
